<template>
  <div class="container-fluid p-0 m-0">
    <Ribbon :data="ribbon" />

    <div class="container-fluid">
      <div
        v-for="item in articles"
        :key="item.id"
        class="container border-bottom my-4 py-3"
      >
        <div class="sv_article">
          <a name="s1" class="nocr"></a>
          <h3 class="nv_h3t">{{ item.title }}</h3>
          <p class="title_detail">{{ item.sub_title }}</p>

          <p class="mt-2">
            <small class="text-success">{{
              moment(item.published_at).format("YYYY/MM/DD")
            }}</small>
          </p>
          <p class="sub_detail">
            <markdown-it-vue
              :content="item.content"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
// @ is an alias to /
import Ribbon from "../components/Ribbon.vue";
import axios from "axios";
import MarkdownItVue from "markdown-it-vue";
var moment = require("moment");

export default {
  name: "Careers",
  components: { Ribbon, MarkdownItVue },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Careers",
    // all titles will be injected into this template
    titleTemplate: "%s | DIGITRAN CO, LTD.",
  },
  data: function () {
    return {
      ribbon: ["Careers", "Recruitment"],
      articles: [],
      moment: moment,
    };
  },
  mounted: function () {
    axios({
      url: "https://cms.digitran.asia/recruitments",
      data: {},
      method: "GET",
    }).then((res) => {
      this.articles = res.data;
    });
  },
};
</script>

<style scoped lang="scss">
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>

