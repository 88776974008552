import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import Careers from '../views/Careers.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Blog from '../views/Blog.vue'
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'
import BlogDetail from '../views/BlogDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/careers',
    name: 'Careers',
    component: Careers
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/news/:id/:slug',
    name: 'NewsDetail',
    component: NewsDetail
  },
  {
    path: '/blog/:id/:slug',
    name: 'BlogDetail',
    component: BlogDetail
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
