<template>
  <div id="app" class="container-fluid p-0 m-0">
    <router-view />
    <Footer />
  </div>
</template>

<style lang="scss">
</style>


<script>
// @ is an alias to /src
import Footer from "./components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer,
  },
};
</script>




<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
body {
  font-family: "Quicksand", sans-serif !important;
}

a:hover {
  color: #117895 !important;
}
//@media (min-width: 1200px) {
//  .container {
//    max-width: 970px;
//  }
//}
</style>
