<template>
  <div class="container-fluid p-0 m-0 dx-light py-1">
    <div id="bg-1" class="container-fluid p-0 m-0 bg-white py-1">
      <div class="dx-center container py-5 text-light">
        <br />
        <br />
        <h3 class="font-weight-bold">Sustainability</h3>
        <br />
        <small>共感共創でしなやかに未来を拓く</small>
        <br />
        <h3>サステナブルなDXの実現へ</h3>
        <br />

        <p class="dx-medium">
          DXのゴールは、固定概念やビジネスモデルを変革し、<br />
          デジタルを用いた新しい顧客体験価値を創出すること。<br />
          その先に、事業環境が変化しても持続的に本質的な強みを発揮し、<br />
          それによって社会的課題を解決していく。<br />
          従業員の働きがいや生きがいにも繋がるサスティナブルなDXの実現。<br />
          DX Partnerと共に目指してみませんか？
        </p>

        <br />
        <br />
      </div>
    </div>

    <div id="bg-2" class="container-fluid p-0 m-0 py-1 bg-light">
      <div class="container py-5 position-relative bg-light">
        <br /><br />
        <div class="text-block bg-white p-4 rounded">
          <h4>お気軽にお問い合わせください。</h4>
          <br />
          <br />

          <p class="small thin">
            DX partnerは、お客様に寄り添い伴走します。<br />
            ひと言にDXと言っても、その意味合いは非常に幅広く、方法は様々です。<br /><br />
            「DXの必要性は分かっていても何から手を付けたらいいのかわからない」「DXは始めてみたものの上手く行っているかわからない」「提案された内容や価格が適正かわからない」など、些細なことでも大丈夫です。<br /><br />
            お気軽にお問合わせくださいませ。<br />
          </p>
          <div class="pb-3">
            <a
              class="mt-4 btn dark btn-outline-primary float-right"
              href="/contact"
              >お問い合わせする →</a
            >
          </div>
        </div>
        <div class="seo-img d-none d-md-inline">
          <img width="500px" src="../assets/livla-ceo.png" alt="" />
          <p class="position-absolute">
            株式会社LIVLA <br />
            代表 奥田絵美
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    data: Array,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dx-light {
  //background: #fdfdfd !important;
}

.dx-feature {
  max-width: 800px;
  span {
    width: 250px;
    display: inline-block;
    margin-right: 20px;
    margin-top: 10px;
    text-align: center;
    padding: 3px;
    border-radius: 4px;
  }
}

@media (max-width: 576px) {
  .dx-center {
    text-align: center;
  }
  .dx-feature {
    justify-content: center;
  }
}

#bg-1 {
  background-image: url(../assets/contact-bg.jpg);
  background-size: cover;
  background-repeat: repeat-x;
  background-position: bottom;
}
#bg-2 {
  //background-image: url(../assets/livla-ceo.png);
  //background-size: cover;
  //background-repeat: no-repeat;
  .text-block {
    display: inline-block;
    max-width: 500px;
  }

  .seo-img {
    position: absolute;
    bottom: 0;
    right: 0px;

    p {
      right: 90px;
      top: 130px;
      font-weight: 400;
      font-size: 0.6em;
    }
  }
}
</style>
