<template>
  <div id="header" class="container-fluid m-0 p-0 position-relative">
    <div
      class="
        container
        d-flex
        flex-column flex-md-row
        align-items-center
        py-4
        px-md-4
        bg-none
        text-dark
      "
    >
      <router-link to="/" class="text-decoration-none text-light">
        <img
          class="img-fluid"
          style="max-width: 200px"
          src="../assets/Color/Light.png"
          alt="DX Partner"
        />
      </router-link>

      <ul
        class="
          nav
          col-12 col-lg-auto
          me-lg-auto
          mb-2
          justify-content-center
          mb-md-0
          d-none d-md-flex
          text-dark
        "
      >
        <li>
          <a href="#section-1" class="nav-link px-1 px-md-3 link-dark"
            >わたしたちのチームづくり</a
          >
        </li>
        <li>
          <a href="#section-4" class="nav-link px-1 px-md-3 link-dark">強み</a>
        </li>
        <li>
          <a href="#section-3" class="nav-link px-1 px-md-3 link-dark"
            >失敗する理由</a
          >
        </li>
        <li>
          <a href="#section-showcase" class="nav-link px-1 px-md-3 link-dark"
            >DX事例</a
          >
        </li>
      </ul>

      <a
        class="
          btn
          dark
          btn-outline-primary
          float-right
          mb-md-0
          d-none d-md-block
        "
        href="/contact"
        >お問い合わせ →</a
      >
    </div>

    <div class="container">
      <h3
        class="text-center text-light mt-5"
        style="
          font-size: 40px;
          font-weight: 500 !important;
          margin-top: 170px !important;
        "
      >
        共感共創でしなやかに未来を拓く
        <br />
        DX Partner
      </h3>
    </div>

    <div id="center" class="bg-white p-5">
      <div class="text-center">
        <img style="width: 80%" src="../assets/partner.svg" alt="" />
      </div>

      <p class="small thin mt-3">
        国内に豊富な人材ネットワークを持ち、BPOで柔軟・効率的な組織を提案する株式会社
        LIVLA。グローバルにハイエンドエンジニアのチームを持ち、開発力・実績が豊富な株式
        会社Internnect。
        <br /><br />
        両社の強みを御社の強みへ。組織・国を超えて繋がり、広がる。共創型の「DX
        Partner」をご提案いたします。
      </p>

      <div class="member-item bg-light">
        <img src="../assets/livla-square.png" alt="" />
        <div>
          <p>
            株式会社LIVLA <br />
            代表 奥田絵美
          </p>
          <p class="small thin">
            弊社はキャリア・スキルの高い女性が多く活躍しています。クライアントに寄り添いながら業務改善・課題解決を得意としている中、経営層から経営課題や戦略についてもご相談いただくようになりました。特にこの数年はDXに絡む案件・相談が急増しています。
            <br /><br />
            かねてより協業実績のあるデジタル領域に強みを持つ株式会社Internnectと共に、現状の課題と未来を見据えた本質的なDXをサポートいたします。
          </p>
        </div>
      </div>
      <div class="member-item bg-light">
        <img src="../assets/internnect-square.png" alt="" />
        <div>
          <p>
            株式会社internnect <br />
            代表 岡本龍一
          </p>
          <p class="small thin">
            Internnectでは、創業当初から築き上げたグローバルなハイエンドエンジニアネットワークを活用し、人材最適化で最大限の効果を出すハリウッド型チーム構築が強みです。
            <br />
            多くのシステム開発を支援する中、「DX」という観点では、「開発力」に加えて「ビジネス戦略」が非常に重要と考えています。
            <br /><br />

            BPO事業を展開しているLIVLAと協業することで、企画から開発、運営まで一気通貫での支援が可能となり、お客様と一緒にDXの成功を目指します。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#header {
  background-image: url(../assets/header-bg.jpg);
  background-position: top;
  background-size: cover;
  height: 800px;
}

@media (max-width: 992px) {
  #center {
    position: absolute;
    border-radius: 1em;
    left: 0;
    right: 0;
    bottom: -450px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px !important;
    margin: 5px !important;

    .member-item {
      margin-top: 20px;
      padding: 10px;
      position: relative;
      padding-left: 70px;
      border-radius: 1em;
      img {
        width: 60px;
        position: absolute;
        left: 5px;
      }
    }
  }
}
@media (min-width: 992px) {
  #center {
    position: absolute;
    border-radius: 1em;
    left: 0;
    right: 0;
    bottom: -450px;
    margin-left: auto;
    margin-right: auto;
    width: 600px; /* Need a specific value to work */

    .member-item {
      margin-top: 20px;
      padding: 15px;
      position: relative;
      padding-left: 130px;
      border-radius: 1em;
      img {
        width: 100px;
        position: absolute;
        left: 15px;
      }
    }
  }
}
</style>
