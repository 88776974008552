<template>
  <div class="container-fluid p-0 m-0">
    <Ribbon :data="ribbon" />

    <div class="container my-5">
      <h1 class="mb-4">Blogs</h1>

      <div class="row">
        <div class="col-md-8">
          <div v-for="item in articles" :key="item.id" class="my-5">
            <router-link
              class="text-decoration-none"
              :to="`/blog/${item.id}/${item.slug}`"
              ><h3 class="text-dark">
                {{ item.title }}
              </h3></router-link
            >
            <p class="mt-2">
              <small class="text-success">{{
                moment(item.published_at).format("YYYY/MM/DD")
              }}</small>
            </p>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /
import Ribbon from "../components/Ribbon.vue";
import axios from "axios";
var moment = require("moment");

export default {
  name: "Blogs",
  components: { Ribbon },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Blog",
    // all titles will be injected into this template
    titleTemplate: "%s | DIGITRAN CO, LTD.",
  },
  data: function () {
    return {
      ribbon: ["Blog", "Tech"],
      articles: [],
      moment: moment,
    };
  },
  mounted: function () {
    axios({
      url: "https://cms.digitran.asia/blogs?_sort=published_date:DESC,id:DESC",
      data: {},
      method: "GET",
    }).then((res) => {
      this.articles = res.data;
    });
  },
};
</script>


<style scoped lang="scss">
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>

