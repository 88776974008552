<template>
  <div class="container-fluid p-0 m-0">
    <Ribbon :data="ribbon" />

    <div class="container-fluid">
      <div class="container border-bottom my-4">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <a name="s1" class="nocr"></a>
            <h1 class="nv_h3t">{{ news.title }}</h1>
            <p class="title_detail">{{ news.sub_title }}</p>

            <p>
              {{ moment(news.published_at).format("YYYY/MM/DD") }}
            </p>
            <div class="content">
              <markdown-it-vue class="md-body" :content="news.content" />
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
// @ is an alias to /
import Ribbon from "../components/Ribbon.vue";
import axios from "axios";
import MarkdownItVue from "markdown-it-vue";
var moment = require("moment");

export default {
  name: "News",
  components: { Ribbon, MarkdownItVue },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "News",
    // all titles will be injected into this template
    titleTemplate: "%s | DIGITRAN CO, LTD.",
  },
  data: function () {
    return {
      ribbon: ["Company News", "Activies"],
      news: [],
      moment: moment,
    };
  },
  mounted: function () {
    axios({
      url: `https://cms.digitran.asia/company-news/${this.$route.params.id}`,
      data: {},
      method: "GET",
    }).then((res) => {
      this.news = res.data;
    });
  },
};
</script>


<style scoped lang="scss">
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.md-body img {
  max-width: 80% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>

