<template>
  <footer class="container-fluid p-0 m-0">
    <div class="bg-dark text-light p-5">
      <img style="height: 60px" src="../assets/Color/Light.png" alt="" />
      <br />
      <small class="ps-3" style="color: #bebebe"
        >© 2021 DX Partner All rights reserved.</small
      >
    </div>
    <div class="small px-5 py-2">
      <img style="height: 15px" src="../assets/footer.svg" alt="" />
    </div>
  </footer>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
