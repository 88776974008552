<template>
  <div id="section-6" class="container-fluid p-0 m-0 bg-white px-2">
    <div class="panel container text-light p-5">
      <div class="d-md-flex justify-content-between font-weight-bold">
        <div>
          <h4 class="mb-3">Contact</h4>

          <h4 class="">
            解決したい課題がまだ曖昧ですか？ <br />
            まずはお気軽にご相談ください。
          </h4>
        </div>
        <div class="pb-3">
          <a
            class="mt-4 btn light btn-outline-primary float-right"
            href="/contact"
            >お問い合わせはこちら →</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    data: Array,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#section-6 {
  .panel {
    background: #8fc2f5;
    border-radius: 1em;
  }
}
</style>
