<template>
  <div id="section-showcase" class="container-fluid py-5">
    <div class="container">
      <h3>これまでのDX事例</h3>
      <h4 class="subtitle">Case collection</h4>

      <div>
        <VueSlickCarousel v-bind="slickOptions">
          <div class="p-4" v-for="item in items" :key="item.title">
            <div class="media item bg-light">
              <div
                class="thumbnail"
                :style="{ backgroundImage: `url(${item.image})` }"
              ></div>
              <div class="conttent p-4">
                <p>{{ item.tag }}</p>
                <h4>
                  {{ item.title }}
                </h4>
                <p class="dx-small">
                  {{ item.desc }}
                </p>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import Img1 from "../assets/news-img-1.png";
import Img2 from "../assets/news-img-2.png";
import Img3 from "../assets/news-img-3.png";

export default {
  name: "MediaSection",
  components: { VueSlickCarousel },
  props: {
    msg: String,
  },
  data: function () {
    return {
      items: [
        {
          tag: "K社様",
          title: "コンタクトセンター導入・分業化による業績改善・顧客体験向上",
          desc: "各式場の業務量や業績のばらつきを課題と捉え、業務の改善を一番の目的とし、パートナリングを強化、デジタルシフトを実現しました。",
          image: Img1,
        },
        {
          tag: "C社様",
          title: "自動販売機の顧客体験を再構築",
          desc: "マーケティング分野におけるDX推進に向けたパートナリングを強化、一気通貫で戦略から実行まで伴走できるパートナーと顧客体験の再構築を企画・実現しました。",
          image: Img2,
        },
        {
          tag: "I社様",
          title: "顧客・行員体験の変革",
          desc: "DXは全社的かつ継続的な変革であるとの考えから、デザイン思考やアジャイル開発人材も備える「伴走型」パートナーと二人三脚で、継続したデジタル・トランスフォーメーションを推進しました。",
          image: Img3,
        },
      ],
      slickOptions: {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ],
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.item {
  //width: 350px;
  //padding-left: 1em;
  //display: inline-block;
  border-radius: 1em;
  min-height: 400px;

  .thumbnail {
    width: 100%;
    height: 150px;
    background-image: url(../assets/news-img-1.png);
    background-size: cover;
  }
}
</style>
