<template>
  <div
    id="section-3"
    class="container-fluid py-5 m-0 bg-white py-1 text-center"
  >
    <div class="container bg-white">
      <div class="">
        <div class="bubble mb-5 p-4">
          <h3>一般的なDXが失敗する原因</h3>
          <h4 class="subtitle">Why it fails?</h4>

          <img
            style="width: 50%"
            src="../assets/icon/fail/fail-group1.svg"
            alt=""
          />
          <p>これらの人材が不足していることに起因。</p>
        </div>
      </div>

      <div class="grid row mt-3" v-for="(r, idx) in items" :key="idx">
        <div class="col-md-6" v-for="item in r" :key="item.title">
          <div class="wrapper position-relative p-3 mb-3">
            <div class="d-flex justify-content-between">
              <div>
                <h4 class="title" v-html="item.title">{{ item.title }}</h4>
                <p class="dx-small">
                  {{ item.content }}
                </p>
              </div>
              <img class="ms-4" :src="item.icon" alt="" />
            </div>
            <span
              :style="{ backgroundColor: item.tagColor }"
              class="tag position-absolute"
              >{{ item.tag }}</span
            >
          </div>
        </div>
      </div>

      <div class="container my-5">
        <!--<p class="my-3">
          DX推進に必要なリーダーとスペシャリストたちに不安がありますか？
        </p>-->
        <h4 class="my-3">DX Partnerなら大丈夫。</h4>
        <h3 class="my-3">よくあるDX失敗の3つを解決します。</h3>
        <img
          class="fail-img"
          src="../assets/icon/fail/fail-explain.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import human from "../assets/icon/fail/human.svg";
import money from "../assets/icon/fail/money.svg";
import organize from "../assets/icon/fail/organize.svg";
import team from "../assets/icon/fail/team.svg";
import pig from "../assets/icon/fail/pig.svg";
import docs from "../assets/icon/fail/docs.svg";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data: function () {
    return {
      items: [
        [
          {
            tag: "人材",
            tagColor: "#D08E5D",
            title: "社内にDX人材が<br/>いない",
            content: `そもそもITに精通した人材がいないため、自社の課題に気づく機会がない。`,
            icon: human,
          },
          {
            tag: "人材",
            tagColor: "#D08E5D",
            title: "正しいパートナーの選定が出来ない",
            content: `ビジョンとミッションを深く理解したパートナーが必要。`,
            icon: team,
          },
        ],
        [
          {
            tag: "コスト",
            tagColor: "#615DD0",
            title: "要件定義が曖昧でコストが膨れがち",
            content: `ITに対して先を見通す知見や構築スキルが乏しいためコストがかかる。`,
            icon: money,
          },
          {
            tag: "コスト",
            tagColor: "#615DD0",
            title: "ROI等が不明瞭で予算がおりない",
            content: `社内プレゼンが通らず、何を解決するためのDXなのか理解に乏しい`,
            icon: pig,
          },
        ],
        [
          {
            tag: "プロセス",
            tagColor: "#D05DA4",
            title: "プロジェクト化の範囲を定義出来ない",
            content: `知見が乏しいためDXでできること・できないことがわからない。`,
            icon: docs,
          },
          {
            tag: "プロセス",
            tagColor: "#D05DA4",
            title: "自社に合うDXを<br/>推進することができない",
            content: `ビジョンとミッションを深く理解したパートナーが必要。`,
            icon: organize,
          },
        ],
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bubble {
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  border-color: #000;
  border-radius: 1em;
}
.fail-img {
  width: 95%;
}

.wrapper {
  background: #f5f5f5;
  //display: inline-block;
  border-radius: 1em;
  text-align: left;
  padding: 30px !important;

  .title {
    font-size: 1.15em;
    //font-weight: 400;
  }

  .tag {
    left: -15px;
    top: -15px;
    width: 45px;
    height: 45px;
    color: #fff;
    border-radius: 3em;
    font-size: 0.6em;
    text-align: center;
    line-height: 45px;
  }
}

@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .grid {
    width: 800px;
    margin: 0 auto;
  }
  .fail-img {
    width: 70%;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
</style>
