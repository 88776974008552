<template>
  <div class="container-fluid m-0 p-3 mb-5 ribbon">
    <div class="d-flex small">
      <ul class="list-inline mx-auto my-0 justify-content-center">
        <li v-for="item in data" :key="item" class="list-inline-item px-2">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    data: Array,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ribbon {
  background: #f0f0f0;
}
</style>
