<template>
  <div class="container-fluid p-0 mb-5 m-0">
    <Header />

    <TopBanner />

    <MainBoard />

    <Fail />

    <Feature />

    <LivlaCeo />

    <Media />

    <Contact />
  </div>
</template>


<script>
// @ is an alias to /
import Header from "../components/Header.vue";
import Feature from "../components/Feature.vue";
import Fail from "../components/Fail.vue";
import Media from "../components/Media.vue";
import Contact from "../components/Contact.vue";
import LivlaCeo from "../components/LivlaCeo.vue";
import MainBoard from "../components/MainBoard.vue";
import TopBanner from "../components/TopBanner.vue";

export default {
  name: "DX Partner",
  components: {
    Header,
    TopBanner,
    Feature,
    Fail,
    Media,
    Contact,
    LivlaCeo,
    MainBoard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Home",
    // all titles will be injected into this template
    titleTemplate: "%s | DX Partner",
  },
  data: function () {
    return {
      news: [],
      services: [],
    };
  },
};
</script>

<style scoped lang="scss">
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  #type {
    height: 300px;
    line-height: 300px;
    font-size: 30px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #type {
    height: 400px;
    line-height: 400px;
    font-size: 30px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #type {
    height: 400px;
    line-height: 400px;
    font-size: 40px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  #type {
    height: 500px;
    line-height: 500px;
    font-size: 60px;
  }
}
</style>

