<template>
  <div id="section-4" class="section container-fluid py-5 m-0 py-1 bg-light">
    <div class="">
      <h3 class="text-center m-3">DX Partnerの特徴</h3>
      <h4 class="text-center subtitle">Features</h4>

      <div class="d-flex justify-content-center m-4 feature-icon">
        <img class="" src="../assets/feature-icon-1.svg" alt="" />
        <img class="m-4" src="../assets/feature-icon-2.svg" alt="" />
        <img class="" src="../assets/feature-icon-3.svg" alt="" />
      </div>

      <div
        :class="[`item`, `container`, item.class]"
        v-for="item in items"
        :key="item.title"
      >
        <div
          class="img-bg"
          :style="{ backgroundImage: `url(${item.bgImage})` }"
        ></div>

        <div class="content text-center bg-white">
          <h2 class="my-4">
            <img height="30px" class="icon" :src="item.icon" /> &nbsp;
            {{ item.subtitle }}
          </h2>
          <p class="small thin" v-html="item.text">
            {{ item.text }}
          </p>

          <img class="image-block" :src="item.explainImage" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon1 from "../assets/feature-i-hand.svg";
import featureImg1 from "../assets/feature-img-1.jpg";
import featureExplain1 from "../assets/feature-explain-1.svg";

import icon2 from "../assets/feature-i-tree.svg";
import featureImg2 from "../assets/feature-img-2.jpg";
import featureExplain2 from "../assets/feature-explain-2.svg";

import icon3 from "../assets/feature-i-people.svg";
import featureImg3 from "../assets/feature-img-3.jpg";
import featureExplain3 from "../assets/feature-explain-3.svg";

export default {
  name: "FeatureSection",
  props: {
    msg: String,
  },
  data: function () {
    return {
      items: [
        {
          icon: icon1,
          subtitle: "チームづくり",
          text: "御社のビジョン・経営戦略を軸にフェーズ・ニーズに合わせた人材を選抜。<br/>御社とともにエコシステムを構築いたします。",
          noImage: icon1,
          bgImage: featureImg1,
          explainImage: featureExplain1,
          class: "right",
        },
        {
          icon: icon2,
          subtitle: "スモールスタート",
          text: `御社のビジョン・経営戦略を軸に、最初にDXの全体像・ゴールを描きます。<br/>それをプロジェクト単位に分け、さらに機能単位・業務単位のPoCレベルに分割して進めることにより、<br/>スモールピースの検証からスタートできます。<br/><br/>ひとつひとつのプロジェクトの規模を小さくすることで、<br/>短期間でのアウトプットが可能です。<br/>それを効果検証した実績の上でROIを判断することができます。`,
          bgImage: featureImg2,
          explainImage: featureExplain2,
          class: "left",
        },
        {
          icon: icon3,
          subtitle: "一気通貫のハンズオン支援",
          text: "様々なフェーズの課題解決を支援. <br/>アドバイスにとどまらず各担当部署の現場と伴走する実働型支援により、貴社の人材価値を高めます。",
          bgImage: featureImg3,
          explainImage: featureExplain3,
          class: "right",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.item {
  margin-bottom: 100px !important;
}
.section {
  .item.container {
    .img-bg {
      height: 400px;
      background-size: cover;
      background-position: center;
    }
    .content {
      max-width: 65%;
      margin: 0 auto;
      margin-top: -150px;
      padding: 2em;
      border-radius: 1em;
      .image-block {
        margin-top: 50px;
        width: 90%;
        //display: none;
      }
    }
  }
}

.feature-icon {
  img {
    width: 200px;
  }
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  .item {
    margin-bottom: 30px !important;
  }
  .feature-icon {
    img {
      width: 30%;
    }
  }

  .section {
    .item.container {
      .img-bg {
        display: none;
      }
      .content {
        max-width: 100%;
        margin: 0 auto;
        //margin-top: -150px;
        //padding: 2em;
        border-radius: 1em;
        .image-block {
          margin-top: 20px;
          width: 100%;
          //display: none;
        }
      }
    }
  }
}
</style>
