<template>
  <div class="container-fluid p-0 m-0">
    <Ribbon :data="ribbon" />

    <div class="container-fluid">
      <div class="container border-bottom my-4">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <a name="s1" class="nocr"></a>
            <h1 class="">{{ article.title }}</h1>
            <p class="title_detail">{{ article.subtitle }}</p>

            <p>
              {{ moment(article.published_at).format("YYYY/MM/DD") }}
            </p>

            <img
              v-if="article.image"
              :src="article.image.url"
              class="img-fluid my-3"
              alt="image"
            />

            <div class="content">
              <markdown-it-vue class="md-body" :content="article.content" />
            </div>
          </div>

          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /
import Ribbon from "../components/Ribbon.vue";
import axios from "axios";
import MarkdownItVue from "markdown-it-vue";
var moment = require("moment");

export default {
  name: "BlogDetail",
  components: { Ribbon, MarkdownItVue },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Blog",
    // all titles will be injected into this template
    titleTemplate: "%s | DIGITRAN CO, LTD.",
  },
  data: function () {
    return {
      options: {
        markdownIt: {
          linkify: true,
        },
        linkAttributes: {
          attrs: {
            target: "_self",
            rel: "noopener",
          },
        },
      },
      ribbon: ["Blog", "Tech"],
      article: {},
      moment: moment,
    };
  },
  mounted: function () {
    axios({
      url: `https://cms.digitran.asia/blogs/${this.$route.params.id}`,
      data: {},
      method: "GET",
    }).then((res) => {
      this.article = res.data;
    });
  },
};
</script>


<style lang="scss">
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.md-body img {
  max-width: 80% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>

