<template>
  <div class="container-fluid p-0 m-0">
    <Ribbon :data="ribbon" />

    <div
      v-for="item in articles"
      :key="item.id"
      class="container-fluid border-bottom mb-4"
    >
      <div class="container">
        <div class="sv_article">
          <a name="s1" class="nocr"></a>
          <h3 class="nv_h3t">{{ item.name }}</h3>
          <p class="title_detail">{{ item.sub_title }}</p>
          <div class="content">
            <markdown-it-vue class="md-body" :content="item.content" />
          </div>
        </div>

        <img
          class="border-img center-block"
          alt="Service"
          :src="item.image.url"
        />
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /
import Ribbon from "../components/Ribbon.vue";
import axios from "axios";
import MarkdownItVue from "markdown-it-vue";
var moment = require("moment");

export default {
  name: "Blogs",
  components: { Ribbon, MarkdownItVue },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Services",
    // all titles will be injected into this template
    titleTemplate: "%s | DIGITRAN CO, LTD.",
  },
  data: function () {
    return {
      ribbon: ["Development", "DX", "Indoor Map", "Marketing"],
      articles: [],
      moment: moment,
    };
  },
  mounted: function () {
    axios({
      url: "https://cms.digitran.asia/services",
      data: {},
      method: "GET",
    }).then((res) => {
      this.articles = res.data;
    });
  },
};
</script>

<style scoped lang="scss">
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.border-img {
  max-width: 80%;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .border-img {
    max-width: 60%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .border-img {
    max-width: 50%;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .border-img {
    max-width: 50%;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .border-img {
    max-width: 50%;
  }
}

.md-body img {
  max-width: 80% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>

