<template>
  <div id="header" class="container-fluid m-0 p-0 position-relative">
    <div
      class="
        container
        d-flex
        flex-column flex-md-row
        align-items-center
        py-4
        px-md-4
        bg-none
        text-light
      "
    >
      <router-link to="/" class="text-decoration-none text-dark">
        <img
          class="img-fluid"
          style="max-width: 200px"
          src="../assets/Color/Light.png"
          alt="DX Partner"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#header {
}
</style>
