<template>
  <div class="container-fluid pb-5 bg-light">
    <HeaderLight />

    <div class="container bg-light">
      <div class="center bg-white">
        <div class="heading">
          <h1>お問い合わせ</h1>
          <h5 class="subtitle">Contact</h5>
        </div>

        <p class="my-5">
          DX Partnerに興味を持っていただきありがとうございます。お困りのこ
          とがあればお気軽にお問い合わせください。
        </p>

        <form>
          <div class="form-group mt-3">
            <label class="mb-1" for="exampleInputEmail1">Eメール</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="入力してください。"
            />
          </div>

          <div class="form-group mt-3">
            <label class="mb-1" for="exampleInputEmail1">お名前</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="入力してください。"
            />
          </div>

          <div class="form-group mt-3">
            <label class="mb-1" for="exampleInputEmail1">電話番号</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="入力してください。"
            />
          </div>

          <div class="form-group mt-3">
            <label class="mb-1" for="exampleFormControlSelect1">部署種別</label>
            <select class="form-control" id="exampleFormControlSelect1">
              <option>選択してください。</option>

              <option value="DX推進部関連">DX推進部関連</option>
              <option value="IT新規事業推進部関連">IT新規事業推進部関連</option>
              <option value="新規事業推進部関連">新規事業推進部関連</option>
              <option value="経営企画部関連">経営企画部関連</option>
              <option value="システム関連部関連">システム関連部関連</option>
              <option value="その他">その他</option>
            </select>
          </div>

          <div class="form-group mt-3">
            <label class="mb-1" for="exampleFormControlSelect1">役職種別</label>
            <select class="form-control" id="exampleFormControlSelect1">
              <option>選択してください。</option>
              <option value="代表取締役相当">代表取締役相当</option>
              <option value="取締役相当">取締役相当</option>
              <option value="執行役員相当">執行役員相当</option>
              <option value="部長相当">部長相当</option>
              <option value="課長相当">課長相当</option>
              <option value="その他">その他</option>
            </select>
          </div>

          <div class="form-group mt-3">
            <label class="mb-1" for="exampleInputEmail1">会社名 *</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="入力してください。"
            />
          </div>

          <label class="mb-1 mt-3" for="exampleInputEmail1"
            >現在の課題についてお伺いします *</label
          >
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">
              解決したい課題が明確にある
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">
              作りたいシステムのイメージがある
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">
              作りたいシステムのイメージがある（曖昧でもある）
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1"> その他 </label>
          </div>

          <label class="mb-1 mt-3" for="exampleInputEmail1"
            >課題解決の目的について *</label
          >
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">
              自社の業務改善（コスト削減）のため
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">
              売上拡大のため
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1"> その他 </label>
          </div>

          <div class="form-group mt-3">
            <label class="mb-1" for="exampleInputEmail1"
              >お問い合わせ内容</label
            >
            <textarea
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="ご相談内容を詳しくお聞かせください。"
            />
          </div>

          <p class="mt-5">個人情報のお取り扱いについて</p>
          <p class="small">
            お客様の個人情報は、資料の送付、もしくはお問い合わせに対してのご返信を差し上げることを利用目的とし、当社にて適切に管理させていただきます。お客様の個人情報をお客様の同意無しに第三者に開示・提供することはありません。（法令などにより開示を求められた場合を除く。）その他のお客様の個人情報の取り扱いにきましては、プライバシーポリシーをご覧ください。
            <br />
            当社のプライバシーポリシーにご同意いただける方は「同意する」を選択してください。
          </p>

          <div class="">
            <div class="form-check d-flex justify-content-center my-4">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
              />
              <label class="form-check-label" for="exampleCheck1">
                &nbsp;&nbsp;同意する</label
              >
            </div>
          </div>
          <div>
            <button
              type="submit"
              style="display: block"
              class="xcenter btn dark btn-primary mt-2"
            >
              内容を確認する
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import HeaderLight from "../components/HeaderLight.vue";

// @ is an alias to /
var moment = require("moment");

export default {
  name: "Blogs",
  components: { HeaderLight },

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "About",
    // all titles will be injected into this template
    titleTemplate: "%s | DX Partner",
  },
  data: function () {
    return {
      ribbon: ["About", "Vision", "Mission", "Business"],
      data: {},
      about: {},
      moment: moment,
    };
  },
};
</script>


<style lang="scss">
input[type="text"],
textarea {
  background-color: #f5f5f5;
  border-radius: 0.5em;
}

.xcenter {
  margin: 0 auto !important;
}
select {
  background-color: #f5f5f5;

  border-radius: 1em;
}

.container {
  .center {
    max-width: 680px;
    border-radius: 1em;
    padding: 30px 10px;
    margin: 0 auto;
    font-family: "Noto Sans JP", sans-serif;
  }
}

.heading {
  text-align: center;
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .heading {
    text-align: left;
  }
  .container {
    .center {
      padding: 100px;
      margin: 0 auto;
    }
  }
}
</style>

