<template>
  <div class="container-fluid"></div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#center {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -150px;
  margin-left: auto;
  margin-right: auto;
  width: 600px; /* Need a specific value to work */
}
</style>
