<template>
  <div class="container-fluid m-0 p-0">
    <Ribbon :data="ribbon" />

    <div class="container">
      <div class="article_company information">
        <div class="des my-2 border-bottom py-2">
          <div class="contents">
            <h4 class="pb-4">VISION</h4>
            <div class="detail">
              <markdown-it-vue :content="about.vision" />
            </div>
          </div>
        </div>

        <div class="des my-2 border-bottom py-2">
          <div class="contents">
            <h4 class="pb-4">MISSION</h4>
            <div class="detail">
              <markdown-it-vue :content="about.mission" />
            </div>
          </div>
        </div>

        <div class="des my-2 border-bottom py-2">
          <div class="contents">
            <h4 class="pb-4">BUSINESS</h4>
            <div class="detail">
              <markdown-it-vue :content="about.business" />
            </div>
          </div>
        </div>

        <div class="des my-2 py-2">
          <div class="contents">
            <h4 class="pb-4">COMPANY INFORMATION</h4>
            <div class="table-responsive" style="max-width: 800px">
              <table class="table table-bordered">
                <tr>
                  <td class="py-2">Company Name</td>
                  <td class="py-2">{{ data.name }}</td>
                </tr>
                <tr>
                  <td class="py-2">Established Date</td>
                  <td class="py-2">{{ data.established_date }}</td>
                </tr>
                <tr>
                  <td class="py-2">Director</td>
                  <td class="py-2">{{ data.director }}</td>
                </tr>
                <tr>
                  <td class="py-2">Capital</td>
                  <td class="py-2">{{ data.capital }}</td>
                </tr>
                <tr>
                  <td class="py-2">Address</td>
                  <td class="py-2">{{ data.address }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /
import Ribbon from "../components/Ribbon.vue";
import axios from "axios";
import MarkdownItVue from "markdown-it-vue";
var moment = require("moment");

export default {
  name: "Blogs",
  components: { Ribbon, MarkdownItVue },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "About",
    // all titles will be injected into this template
    titleTemplate: "%s | DIGITRAN CO, LTD.",
  },
  data: function () {
    return {
      ribbon: ["About", "Vision", "Mission", "Business"],
      data: {},
      about: {},
      moment: moment,
    };
  },
  mounted: function () {
    axios({
      url: "https://cms.digitran.asia/company-profile",
      data: {},
      method: "GET",
    }).then((res) => {
      this.data = res.data;
    });

    axios({
      url: "https://cms.digitran.asia/about",
      data: {},
      method: "GET",
    }).then((res) => {
      this.about = res.data;
    });
  },
};
</script>


<style lang="scss">
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.md-body img {
  max-width: 80% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>

