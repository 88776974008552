<template>
  <div id="section-1" class="container-fluid p-0 m-0 bg-white py-1">
    <div class="container my-5 text-center">
      <br /><br /><br /><br /><br /><br />
      <h3>お客様に寄り添い伴走します</h3>
      <h4 class="subtitle">Team Building</h4>

      <p>両社の強みを御社の強みへ。</p>
      <p>
        DXのゴールは、固定概念やビジネスモデルを変革し、<br />
        デジタルを用いた新しい顧客体験価値を創出すること。<br />
        御社のビジョン・経営戦略を軸にフェーズ・ニーズに合わせた人材を選抜。<br />
        御社とともにエコシステムを構築いたします。
      </p>

      <img
        class="main-img img-fluid my-5"
        style="max-width: 95%"
        src="../assets/core-img.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#section-1 {
  padding-top: 300px !important;
  min-height: 300px;
  background-image: url(../assets/world-bg.png);
  background-position: contain;
  background-color: #f5f5f5;
  background-repeat: repeat-x;
}
.main-img {
  width: 95%;
}
@media (min-width: 768px) {
  .main-img {
    width: 80%;
  }
}
</style>
